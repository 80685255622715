<template>
  <div>
    <div class="d-flex">
      <v-text-field
        @keydown.enter.prevent="add()"
        v-model="newReceiver"
        label="Receivers"
        outlined
        placeholder="Add an email address"
        ref="receiver"
        :rules="emailRules"
      />
      <v-btn
        @click="add()"
        color="red"
        class="white--text ml-2 mt-2"
        :disabled="newReceiver === '' || !$refs.receiver.valid"
      >Add</v-btn>         
    </div>
    <div>
      <span v-if="receivers.length === 0">No receivers added</span>
      <v-chip
        v-else
        close
        @click:close="remove(item)"
        v-for="item in receivers"
        :key="item"
        class="my-1 mr-2"
      >
        {{ item }}
      </v-chip>
    </div>
  </div>
</template>

<script>
export default {
  name: "ReceiversComponent",
  props: {
    receivers: {
      type: Array,
    }
  },
  data() {
    return {
      newReceiver: '',
      emailRules: [
        (v) =>
          !v ||
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
          "Must be a valid E-mail address",
      ],
    }
  },
  methods: {
    add() {
      this.$emit('add', this.newReceiver)
      this.newReceiver = ''
    },
    remove(item) {
      this.$emit('remove', item)
    },
    clearReceiver(){
      this.newReceiver = ''
    },
  }
}
</script>